<template>
    <div>
      <van-nav-bar title="电费充值" left-text="返回" right-text="查询订单" left-arrow @click-left="onClickLeft()" @click-right="$router.push('query')"/>
    </div>
  <van-overlay :show="show" @click="show = false">
    <van-loading type="spinner" vertical class="loading"/>
  </van-overlay>
  <van-cell-group>
    <van-field v-model="cardNum" label="户号" placeholder="请输入户号" clearable right-icon="question-o" v-on:click-input="$router.push('/accountNumber')" v-on:clickRightIcon="$router.push('/accountNumberDescription')" />
  </van-cell-group>
  <van-cell-group>
    <van-field v-model="city" label="地区" placeholder="请输入地区(例如：北京)" clearable right-icon="location-o" v-on:clickRightIcon="aearFn(1)" />
  </van-cell-group>

  <div class="recharge-list-view">

      <div class="recharge-title" v-if="slowList.length!=0">
        <b>电费慢充</b>
        <span>72小时内到账,请耐心等待</span>

        <div class="recharge-title-btn">
          <nut-row>
            <nut-col :span="8" :key="item.id" v-for="item in slowList" v-on:click="setItem(item)">
              <div :class="{'flex-content':true,active:item.id == obj.id}">
                <span class="recharge-discount">{{100*(item.marketRate)}}折</span>
                <b>{{item.amount}}元</b>
                <p>售{{(item.amount * item.marketRate).toFixed(2)}}</p>
              </div>
            </nut-col>
          </nut-row>
        </div>
      </div>


    <div class="recharge-title recharge-title-1" v-if="fastList.length!=0">
      <b>电费快充</b>
      <span>24小时内到账,请耐心等待</span>
      <div class="recharge-title-btn">
        <nut-row>
          <nut-col :span="8" :key="item.id" v-for="item in fastList"  v-on:click="setItem(item)">
            <div :class="{'flex-content':true,active:item.id == obj.id}">
              <span class="recharge-discount">{{100*(item.marketRate)}}折</span>
              <b>{{item.amount}}元</b>
              <p>售{{(item.amount * item.marketRate).toFixed(2)}}</p>
            </div>
          </nut-col>
        </nut-row>
      </div>
    </div>

    <div class="recharge-explain">
      <b>电费预存说明</b>
      <p>到账时间：正常情况0-24小时内到账，高峰期延迟至72小时内到账（电费可能会以多次如50元/100元等分次到账，总值200元）可叠加充值；</p>
      <p>家用电和工用电都可以充，下单请准确填写用电户号和所在省份城市</p>
      <p>如因用电户号和所在省份填写错误，由用户自行承担，请务必准确填写用电户号和所在城市；</p>
      <p>充值金额要大于欠费金额(慢充大额到账较慢,还请耐心等待)</p>
      <p>充值成功后请登录（网上国网APP）进行查询；</p>
    </div>
  </div>

  <div class="recharge-btn">
    <nut-row>
      <nut-col :span="16">
        <div class="flex-content payment">
          应付 {{money||0}}元
        </div>
      </nut-col>
      <nut-col :span="8" v-on:click="pay()">
        <div class="flex-content flex-content-light flex-content-btn">
         支付
        </div>
      </nut-col>
    </nut-row>
  </div>

</template>
<script>

    import AMapLoader from '@amap/amap-jsapi-loader';
    import cookieUtil from '../utils/CookieUtil'

import storage from '../utils/Storage'

export default {
  name: 'RechargeElctricityBill',
  data(){
    return {
      cardNum:"",
      error:true,
      slowList:[
        {"id":1,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":50,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":2,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":100,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":5,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":150,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:43:40","updateTime":"2021-08-20 11:43:40"},
        {"id":6,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":200,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"},
        {"id":7,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":250,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"},
        {"id":8,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.92,"vipRate":0.95,"amount":300,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"}
        ],
      fastList:[
        {"id":10,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":50,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":12,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":100,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-10 10:23:31","updateTime":"2021-08-20 11:39:45"},
        {"id":15,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":150,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:43:40","updateTime":"2021-08-20 11:43:40"},
        {"id":16,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":200,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"},
        {"id":17,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":250,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"},
        {"id":18,"agentId":204,"productCode":"FAKA","productName":"FAKA移动充值","routingCost":0.94,"vipRate":0.95,"amount":300,"marketRate":0.96,"sequence":0,"supportIsp":"移动","isShow":1,"addTime":"2021-08-20 11:44:47","updateTime":"2021-08-20 11:44:47"}
      ],
      money:0.0,
      obj:{},
      isp:"",
      recommendUserId:0,
      token:null,
      city:"",
      show:false,
      type:0,
      userInfo:{}
    }
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:#f5f5f5')
  },
  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  },
  created(){
    //获取登录token
    this.token = this.$route.query.token
    this.expire = this.$route.query.expire
    if(this.token && this.expire){
      cookieUtil.setCookie(cookieUtil.token,this.token,this.expire);
    }
    console.log('recommendUserId',this.recommendUserId);
    let accountNumber = storage.getObjOpsValue("accountNumber");
    if(accountNumber != null){
      console.log("accountNumber",accountNumber);
      this.city = accountNumber.city;
      this.cardNum = accountNumber.accountNumber;
      if(this.type == 0){
        this.isp = '国家电网';
      }else if(this.type == 1){
        this.isp = '南方电信';
      }else if(this.type == 2){
        this.isp = '中国燃气';
      }else if(this.type == 3){
        this.isp = '华润燃气';
      }else if(this.type == 4){
        this.isp = '港华燃气';
      }
    }
  },
  watch:{
    'cardNum'(newValue, oldValue){
          if(newValue!=""){
            let isp = '国家电网';
            if(this.type == 0){
              isp = '国家电网';
            }else if(this.type == 1){
              isp = '南方电信';
            }else if(this.type == 2){
              isp = '中国燃气';
            }else if(this.type == 3){
              isp = '华润燃气';
            }else if(this.type == 4){
              isp = '港华燃气';
            }
            this.productconfig(isp);
          }
      }
  },
  methods:{
      pay(){
        if(this.cardNum==""){
            this.$toast.fail('请输入正确的户号');
            return;
        }
        if(this.money!=((this.obj.amount * (this.userInfo.level?this.obj.vipRate:this.obj.marketRate)).toFixed(2))){
          this.$toast.fail('请求选择充值金额');
          return;
        }
        let pay_type = this.isWeiXin();

        // let url = '/app/wx';
        let url = '/app/';
        if(pay_type){
          url = url + "wx";
        }else{
          url = url + "ali";
        }

         this.$dialog.confirm({
          title: '充值确认',
          message:
           `当前要充值的户号为 “${this.cardNum}” ,为了避免充值户号,请仔细核对,户号错误充值失败无法退款,确定给这个户号充值吗?`,
        })
          .then(() => {
            this.axios.post(url+'/pay', {
                      isp:this.isp,
                      orderAmt:this.obj.amount,
                      productCode:this.obj.productCode,
                      phoneNumber:this.cardNum,
                      recommendUserId:this.recommendUserId,
                      type:this.type
                    }).then((response) => {
                      if(response!=undefined && response!='' && response!=401 && response!=500 && pay_type){
                          window.location.href = response;
                      }
                      if(response!=undefined && response!='' && response!=401 && response!=500 && !pay_type){
                        document.write(response)
                      }

                        // this.slowList = response;
                    }).catch(function (error) {
                        console.log(error);
                    });
          })
          .catch(() => {
            // on cancel
          });



      },
      setItem(obj){
        this.obj = obj;
        let rate = this.userInfo.level?obj.vipRate:obj.marketRate
        this.money = (obj.amount * rate).toFixed(2);
        this.type= obj.type
      },
      productconfig(isp){
          if(isp == '中国燃气' || isp == '华润燃气' || isp == '港华燃气'){
            this.$toast.fail('账户选择类型不正确!');
            this.slowList = [];
            this.fastList = [];
            //制空选择的金额
            this.setItem({amount:0,marketRate:0,vipRate:0});
            return;
          }
          this.axios.post('/app/productconfig/electricityBillList', {
                  isp
                }).then((response) => {
                    this.slowList = response.slowList;
                    this.fastList = response.fastList;
                    //制空选择的金额
                    this.setItem({amount:0,marketRate:0,vipRate:0});
                }).catch(function (error) {
                    console.log(error);
                });

      },
      isWeiXin(){
          //获取浏览器属性
          //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
          let ua = window.navigator.userAgent.toLowerCase();
          //通过正则表达式匹配ua中是否含有MicroMessenger字符串
          if(ua.match(/MicroMessenger/i) == 'micromessenger'){
              return true;
          }
          return false;
      },
      aearFn(i){
        this.show = true;
        console.log("获取定位");
        AMapLoader.load({
            "key": "2d4144b5959c0681cdcd635488dae29e",              // 申请好的Web端开发者Key，首次调用 load 时必填
            "version": "1.4.15",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            "AMapUI": {             // 是否加载 AMapUI，缺省不加载
                "version": '1.1',   // AMapUI 缺省 1.1
                "plugins":[],       // 需要加载的 AMapUI ui插件
            }
        }).then((AMap)=>{
            AMap.plugin('AMap.Geolocation', ()=>{
              let geolocation = new AMap.Geolocation({
                // 是否使用高精度定位，默认：true
                enableHighAccuracy: true,
                // 设置定位超时时间，默认：无穷大
                timeout: 10000,
                // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
                buttonOffset: new AMap.Pixel(10, 20)
              })

              geolocation.getCurrentPosition((status,result)=>{
                this.show = false;
                    if(status=='complete'){
                        // result是具体的定位信息
                        this.city = result.addressComponent.city;

                    }else{
                        // 定位出错
                        this.$toast.fail('定位失败请自行输入地区');
                        console.log("定位失败",result);
                    }
              });
            })
        }).catch(e => {
            console.log(e);
        })
      },
      onClickLeft(){
        console.log(1);
        window.history.back();
      }
  }
}
</script>

<style scoped>
.recharge-list-view{
  min-height: 85vh;
  background: #ffffff;
  margin: 10px 10px 50px 10px;
  border-radius: 20px;
  padding: 15px;
}
.recharge-list-view .recharge-title b{
  font-size: 20px;
}
.recharge-title-1{
  margin-top: 20px;
}
.flex-content{
  position:relative;
}
.recharge-discount{
  position: absolute;
  left: -12px;
  top: -2px;
  padding: 0 8px;
  border-radius: 10px 0 10px 0px;
  background: #2BD67E;
  color: #ffffff !important;
  margin: 2px;
}
.recharge-list-view .recharge-title span{
  font-size: 12px;
  color: #cccccc;
  margin-left: 12px;
}
.recharge-title-btn{
  text-align: center;
}
.recharge-title-btn .flex-content{
  border: 1px solid #cccccc;
  margin: 10px 5px;
  padding: 20px 0 5px 0;
  border-radius: 15px;
}
.recharge-title-btn .flex-content p{
  margin: 5px;
  font-size: 12px;
}
.recharge-explain{
  background: #F0FCF8;
  padding: 10px;
}
.recharge-explain p{
  margin: 3px;
  font-size: 13px;
  color: #929292;
}
.recharge-btn{
  background: #ffffff;
  border-top: 1px solid #cccccc;
  height: 60px;
  line-height: 60px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.payment{
  margin-left: 20px;
  color: #ff2a2a;
  font-size: 18px;
}
.flex-content-btn{
  background: #2BD67E;
  text-align: center;
  color: #ffffff;
}
.active{
  background: #c9ffd2;
}
.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>
